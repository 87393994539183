<!--
 * @Author: lina
 * @LastEditors: lina
 * @Date: 2024-08-29
 * @LastEditTime: 2024-08-29
 * @Descripttion: 手写板管理
-->
<style lang="scss" scoped>
.textbook {
    @include innerPage(40px 34px 48px 15px);
    @include pageTitle(0);
    @include defalutTable(calc(100% - 50px), 20px);
    @include pageFoot;

    .page-title {
        flex-wrap: wrap;

        .search-form {
            width: 78.67%;
            min-width: 1000px;

            &-wrapper {
                width: 100%;
                margin-top: 16px;
            }
        }

        .el-form-item {
            width: 16%;
            max-width: 190px;
            min-width: 164px;

            &:last-child {
                flex-grow: 1;
                max-width: 340px;
            }
        }
    }
}

.dialog-container {
    width: calc(100% + 20px);
    height: 600px;
    box-sizing: border-box;
    padding: 10px 20px 0 18px;
    margin: 24px 0 48px;
    overflow: hidden;
    overflow-y: auto;

    .tips {
        color: #e58181;
        height: 24px;
        position: absolute;
        top: 24px;
        right: 120px;
        font-size: 14px;
        @include flexBox;

        .iconfont {
            font-size: 20px;
            margin-right: 4px;
        }
    }

    .reason {
        color: #e58181;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 20px;
        @include flexBox;
        align-items: flex-start;

        span {
            flex-shrink: 0;
        }
    }

    .el-form-item:last-child {
        margin-bottom: 0;
    }
}
</style>

<template>
    <section class="textbook">
        <div class="page-title">
            <h3>手写板管理</h3>
        </div>
        <div class="table-box">
            <div class="table-inner" ref="tableInner" v-loading="dataLoad">
                <el-table :data="tableData" height="100%" >
                    <el-table-column
                        align="center"
                        prop="scsch_name"
                        label="学校"
                    />
                    <el-table-column
                        align="center"
                        prop="pli_num"
                        label="总手写板数量"
                    />
                    <el-table-column
                        align="center"
                        prop="assigned_board"
                        label="已分配手写板数量 "
                    />
                    <el-table-column
                        align="center"
                        prop="surplus_board"
                        label="待分配手写板数量"
                    />

                  <el-table-column
                      align="center"
                      prop="pls_effective_day"
                      label="有效天数"
                  />
                  <el-table-column prop="expiration_time" label="到期时间" align="center">
                    <template slot-scope="scope">
                      <p>{{ (scope.row.expiration_time * 1000) | formatTime("YYYY-MM-DD HH:mm:ss") }}</p>
                    </template>
                  </el-table-column>
                  <el-table-column align="center" label="操作" width="300">
                      <template  slot-scope="scope">
                          <el-button
                              type="custom_primary"
                              size="medium"
                              @click="showBindingDialog(scope.row.pli_id)"
                          >
                              绑定
                          </el-button>
                          <el-button
                              type="custom_primary"
                              size="medium"
                              @click="showBindingListDialog(scope.row.pli_id)"
                          >
                              绑定记录
                          </el-button>
                          <el-button
                              type="custom_primary"
                              size="medium"
                              @click="showRecoveryDialog(scope.row.pli_id)"
                          >
                              一键回收
                          </el-button>
                      </template>
                  </el-table-column>
                </el-table>
            </div>

          <div class="table-foot custom-foot">
            <customPagination :current='tablePage.pageindex' :total="tablePage.total" @pageChange="flippingPage" />
          </div>
        </div>
        <!-- 新增弹窗 -->
        <el-dialog
            :title="(textbookForm.sccou_id && '编辑教材') || '添加教材'"
            width="558px"
            :visible.sync="textbookDialog"
            :show-close="false"
            @close="hideDialog"
        >
            <div class="dialog-container">
                <p class="tips">
                    <i class="iconfont">&#xe63c;</i>
                    {{
                        (dataAudit.sccou_sharestatus == 30 && "已驳回") ||
                        "教材提审需平台审核，请耐心等待..."
                    }}
                </p>
                <div class="reason" v-if="dataAudit.sccou_sharestatus == 30">
                    <span>驳回理由：</span>
                    <p>
                        {{ dataAudit.sccou_reason }}
                    </p>
                </div>
                <el-form
                    class="page-form"
                    label-width="68px"
                    ref="textbookForm"
                    :model="textbookForm"
                    :rules="rules"
                >
                    <el-form-item label="年级" prop="sccou_grade">
                        <el-select
                            v-model="textbookForm.sccou_grade"
                            placeholder="请选择年级"
                            filterable
                            clearable
                        >
                            <el-option
                                v-for="item in gradeList"
                                :key="item.grade_id"
                                :label="item.grade_name"
                                :value="item.grade_id"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="学期" prop="semester">
                        <el-select
                            v-model="textbookForm.semester"
                            placeholder="请选择学期"
                            clearable
                        >
                            <el-option label="上学期" :value="1" />
                            <el-option label="下学期" :value="2" />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="科目" prop="sysub_id">
                        <el-select
                            v-model="textbookForm.sysub_id"
                            placeholder="请选择科目"
                            filterable
                            clearable
                        >
                            <el-option
                                v-for="item in subjectList"
                                :key="item.sysub_id"
                                :label="item.sysub_name"
                                :value="item.sysub_id"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item label="教材版本" prop="syedi_title">
                        <el-input
                            v-model.trim="textbookForm.syedi_title"
                            maxlength="50"
                            placeholder="请输入内容"
                            clearable
                        />
                    </el-form-item>
                    <el-form-item label="版号" prop="syedi_version">
                        <el-input
                            v-model.trim="textbookForm.syedi_version"
                            maxlength="100"
                            placeholder="请输入内容"
                            clearable
                        />
                    </el-form-item>
                </el-form>
            </div>
            <div
                slot="footer"
                :class="{
                    'foot-flex':
                        !textbookForm.sccou_id ||
                        dataAudit.sccou_sharestatus == 30 ||
                        dataAudit.sccou_sharestatus == 5,
                }"
            >
                <el-button type="custom_info" size="small" @click="hideDialog">
                    取 消
                </el-button>
                <el-button
                    style="margin-left: auto"
                    type="custom_success"
                    size="small"
                    v-if="
                        !textbookForm.sccou_id ||
                        dataAudit.sccou_sharestatus == 30 ||
                        dataAudit.sccou_sharestatus == 5
                    "
                    @click="shareData(textbookForm.sccou_id || null)"
                >
                    {{
                        (dataAudit.sccou_sharestatus == 30 && "重新共享") ||
                        "共 享"
                    }}
                </el-button>
            </div>
        </el-dialog>
        <!-- 绑定弹窗 -->
        <el-dialog
            title="绑定"
            width="1400px"
            :visible.sync="bindingDialog"
            @close="hidebindingDialog"
        >
            <div class="dialog-container">
                <div class="tool">
                    <div class="search-form">
                        <el-form inline :model="searchForm" ref="formRef">
                            <el-form-item label="学期" prop="semester">
                                <el-select
                                    v-model="bindingForm.semester"
                                    placeholder="请选择学期"
                                    clearable
                                    @clear="clearSemester"
                                    @change="semesterChange($event)"
                                >
                                    <el-option label="上学期" :value="1" />
                                    <el-option label="下学期" :value="2" />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="班级" prop="sccla_id" >
                                <el-cascader
                                    v-model="bindingForm.sccla_id"
                                    :options="scclaList"
                                    @change="scclaChange()"
                                    :props="{ value: 'key', label: 'label' }"
                                    collapse-tags
                                />
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <div class="table-box">
                    <div class="table-inner" ref="tableInner">
                        <el-table :data="stuUserData" height="100%"  v-loading="tableLoad" @selection-change="handleSelectionChange">
                          <el-table-column type="selection" width="50" align="center" fixed="left"
                                           :selectable="(row)" />
                            <el-table-column
                                align="center"
                                prop="stuser_name"
                                label="学生姓名"
                                min-width="12.05%"
                            />
                            <el-table-column
                                align="center"
                                label="性别"
                                min-width="7.19%"
                            >
                                <template slot-scope="scope">
                                    {{
                                        (scope.row.stuser_sex == 10 && "男") ||
                                        "女"
                                    }}
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop="stuser_account"
                                label="账号"
                                min-width="11.85%"
                            />
                            <el-table-column
                                align="center"
                                prop="stuser_stuno"
                                label="学号"
                                min-width="11.85%"
                            />
                            <el-table-column
                                align="center"
                                label="状态"
                                min-width="7.89%"
                            >
                                <template slot-scope="scope">
                                    <p
                                        class="table-status"
                                        :class="{
                                            danger:
                                                scope.row.stuser_readstatus ==
                                                20,
                                            success:
                                                scope.row.stuser_readstatus ==
                                                10,
                                        }"
                                    >
                                        {{
                                            (scope.row.stuser_readstatus ==
                                                10 &&
                                                "在读") ||
                                            "休学"
                                        }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                label="账号状态"
                                min-width="7.89%"
                            >
                                <template slot-scope="scope">
                                    <p
                                        class="table-status"
                                        :class="{
                                            danger:
                                                scope.row.stuser_accstatus ==
                                                20,
                                            success:
                                                scope.row.stuser_accstatus ==
                                                10,
                                        }"
                                    >
                                        {{
                                            (scope.row.stuser_accstatus == 10 &&
                                                "启用") ||
                                            "禁用"
                                        }}
                                    </p>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="table-foot custom-foot" v-if="stuUserData.length>0">
                        <customPagination
                            :current="stuUserPage.pageindex"
                            :total="stuUserPage.total"
                            @pageChange="flippingPage"
                        />
                    </div>
                </div>
            </div>
            <div
                slot="footer"
                :class="{
                    'foot-flex':
                        !textbookForm.sccou_id ||
                        dataAudit.sccou_sharestatus == 30 ||
                        dataAudit.sccou_sharestatus == 5,
                }"
            >
                <!-- <el-button
                    type="custom_info"
                    size="small"
                    @click="hidebindingDialog"
                >
                    取 消
                </el-button> -->
                <el-button
                    style="margin-left: auto"
                    type="custom_success"
                    size="small"
                    @click="bindingStuUser()"
                >
                    绑 定
                </el-button>
            </div>
        </el-dialog>
        <!-- 绑定记录弹窗 -->
        <el-dialog
            title="绑定记录"
            width="1400px"
            :visible.sync="bindingListDialog"
            @close="hidebindingListDialog"
        >
            <div class="dialog-container">
                <div class="table-box" style="height: 100%;">
                    <div
                        class="table-inner" style="height: 100%;"
                        ref="tableInner"
                        v-loading="bindingDataLoad"
                    >
                        <el-table :data="bindingData" height="100%">
                            <el-table-column
                                align="center"
                                prop="scsch_name"
                                label="学校"
                            />
                            <el-table-column
                                align="center"
                                prop="grade_class"
                                label="班级"
                            />
                            <el-table-column
                                align="center"
                                prop="pls_sendtime"
                                label="操作时间"
                            >
                                <template slot-scope="scope">
                                    <p>
                                        {{
                                            (scope.row.pls_sendtime * 1000)
                                                | formatTime(
                                                    "YYYY-MM-DD HH:mm:ss"
                                                )
                                        }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="操作">
                                <template slot-scope="scope">
                                    <el-button
                                        type="custom_primary"
                                        size="medium"
                                        @click="BindingExport(scope.row.pls_id)"
                                    >
                                        导出
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- <div class="table-foot custom-foot">
                        <customPagination
                            :current="stuUserPage.pageindex"
                            :total="stuUserPage.total"
                            @pageChange="flippingPage"
                        />
                    </div> -->
                </div>
            </div>
        </el-dialog>
        <!-- 一键回收弹窗 -->
        <el-dialog
            title="回收手写板"
            width="1400px"
            :visible.sync="recoveryDialog"
            @close="hideRecoveryDialog"
        >
            <div class="dialog-container" v-loading="historyDataLoad">
                <el-form inline>
                    <el-form-item label="绑定批次" prop="pls_id">
                        <el-select
                            size="mini"
                            style="width: 300px"
                            v-model="pls_id"
                            placeholder="请选择下发批次"
                            filterable
                            @change="historyChange(pls_id)"
                        >
                            <el-option
                                v-for="item in historyData"
                                :key="item.pls_id"
                                :label="item.recovery_title"
                                :value="item.pls_id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>

                <div class="table-box">
                    <div class="table-inner" ref="tableInner">
                        <el-table :data="recoveryData" height="100%" @selection-change="handleRrecoverySelectionChange">
                            <el-table-column type="selection" width="50" align="center" fixed="left"
                                             :selectable="(row)" />
                            <el-table-column
                                align="center"
                                prop="stuser_name"
                                label="姓名"
                            />
                            <el-table-column
                                align="center"
                                prop="stuser_stuno"
                                label="学号"
                            />
                            <el-table-column align="center" label="性别">
                                <template slot-scope="scope">
                                    {{
                                        (scope.row.stuser_sex == 10 && "男") ||
                                        "女"
                                    }}
                                </template>
                            </el-table-column>

                            <el-table-column align="center" label="上学状态">
                                <template slot-scope="scope">
                                    <p
                                        class="table-status"
                                        :class="{
                                            danger:
                                                scope.row.stuser_readstatus ==
                                                20,
                                            success:
                                                scope.row.stuser_readstatus ==
                                                10,
                                        }"
                                    >
                                        {{
                                            (scope.row.stuser_readstatus ==
                                                10 &&
                                                "在读") ||
                                            "休学"
                                        }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop="create_time"
                                label="操作时间"
                            >
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <div
                slot="footer"
                :class="{
                    'foot-flex':
                        !textbookForm.sccou_id ||
                        dataAudit.sccou_sharestatus == 30 ||
                        dataAudit.sccou_sharestatus == 5,
                }"
            >
                <!-- <el-button
                    type="custom_info"
                    size="small"
                    @click="hideRecoveryDialog"
                >
                    取 消
                </el-button> -->
                <el-button
                    style="margin-left: auto"
                    type="custom_success"
                    size="small"
                    @click="recoveryBoard()"
                >
                    一键回收
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import {
    $boardList,
    $batchList,
    $nosendTreelist,
    $stuUserAllList,
    $bindingStuUser,
    $bindingList,
    $getHhistory,
    $recoveryBoard,
    $exportPlaBoardSend,
} from "@api/plaboard";
import { mapState } from "vuex";
import customPagination from "@comp/customPagination";

export default {
    name: "plabord_plabord",
    components: { customPagination },
    computed: {
        ...mapState("common", ["gradeList", "subjectList"]),
    },
    data() {
        return {
            searchForm: {}, // 搜索参数
            tableData: [], // 表格参数
            /* 表格分页参数 */
            tablePage: {
                pageIndex: 1,
                total: 0,
            },
            textbookDialog: false, // 教材编辑显示弹窗
            textbookForm: {}, // 教材编辑表单
            dataAudit: {}, // 教材审核内容
            btnload: false,
            bindingDialog: false, // 绑定弹窗
            scclaList: [], // 班级数据
            bindingSearchForm: {}, // 绑定查询条件
            sccla: [],
            bindingForm: {
                sccla_id:[],
                pli_id:0,
                stuser_ids:[],
            }, // 绑定编辑表单
            stuUserData: [], // 学生数据
            /* 表格分页参数 */
            stuUserPage: {
                pageIndex: 1,
                total: 0,
                pagesize: 10,
            },
            stuUsertotal: 0, //学生数量
            bindingListDialog: false, // 绑定记录弹窗
            bindingData: [], // 绑定记录
            recoveryDialog: false, // 一键回收弹窗
            recoveryData: [], // 批次历史记录
            historyData: [], // 绑定批次数据
            pls_id: "", //批次ID
            exportLoading: false,
            dataLoad: false,
            bindingDataLoad: false,
            historyDataLoad: false,
            bindingMultipleSelection: [], //表格多选储存数据
            recoveryMultipleSelection: [], //回收表格多选储存数据
            /* 表单校验规则 */
            rules: {
                sccou_grade: [
                    {
                        required: true,
                        type: "number",
                        message: "请选择年级",
                        trigger: "change",
                    },
                ],
                semester: [
                    {
                        required: true,
                        type: "number",
                        message: "请选择学期",
                        trigger: "change",
                    },
                ],
                sysub_id: [
                    {
                        required: true,
                        type: "number",
                        message: "请选择教材科目",
                        trigger: "change",
                    },
                ],
                syedi_title: [
                    {
                        required: true,
                        message: "请输入教材版本名称",
                        trigger: "blur",
                    },
                ],
                syedi_version: [
                    {
                        required: true,
                        message: "请输入教材版号",
                        trigger: "blur",
                    },
                ],
            },
            tableLoad:false,
        };
    },
    created() {
        this.getData();
    },
    methods: {
        /** 获取数据 */
        async getData() {
            this.dataLoad = true;
            let { data: res } = await $batchList(this.tablePage);
            console.log(res);
            this.tableData = res.data;
            this.tablePage.total = res.allcount; //总数
            this.dataLoad = false;
            this.$forceUpdate();
        },
        /** 搜索 */
        searchData() {
            this.tableData = [];
            this.tablePage.pageIndex = 1;
            this.getData();
        },
        /** 翻页 */
        flippingPage(val) {
            this.stuUserPage.pageIndex = val;
            this.getStuUserList();
        },
        /** 开启弹窗 */
        showDialog(data) {
            this.textbookDialog = true;
            if (data.sccou_id) {
                let keys = [
                    "sccou_id",
                    "sccou_grade",
                    "semester",
                    "syedi_title",
                    "syedi_version",
                    "sysub_id",
                ];
                let form = {};
                keys.map((key) => {
                    form[key] = data[key];
                });
                this.dataAudit = {
                    sccou_sharestatus: data.sccou_sharestatus,
                    sccou_reason: data.sccou_reason,
                };
                this.textbookForm = { ...form };
            }
        },
        /** 关闭弹窗 */
        hideDialog() {
            this.textbookDialog = false;
            this.dataAudit = {};
            this.textbookForm = {};
        },
        /** 重置表单 */
        resetForm(ref) {
            this.$refs[ref].resetFields();
        },
        /** 开启绑定弹框 */
        showBindingDialog(pli_id) {
          console.log(pli_id);
            this.bindingForm.pli_id=pli_id;
            this.bindingDialog = true;
        },
        hidebindingDialog() {
            this.bindingDialog = false;
            this.scclaList = [];
            this.stuUserData = [];
            this.bindingForm = {
                sccla_id:[]
            };
            this.stuUserPage.pageIndex = 1;
        },
        /** 选择学期后获取班级列表 */
        async semesterChange(val) {
            this.bindingForm.sccla_id=[]
            this.bindingForm.stuser_ids=[]
            this.stuUserData=[]
            this.scclaList=[]
            if(val!= ''){
                let { data: res } = await $nosendTreelist({ sccla_semester: val });
                if (res) {
                    this.scclaList = res;
                }
            }
        },
        clearSemester(){
            this.bindingForm.sccla_id=[]
            this.bindingForm.stuser_ids=[]
            this.stuUserData=[]
            this.scclaList=[]

        },
        /** 表格多选触发 */
        handleSelectionChange(val) {
          this.bindingMultipleSelection = val;
        },
        /** 回收表格多选触发 */
        handleRrecoverySelectionChange(val) {
          this.recoveryMultipleSelection = val;
        },
        /** 选择班级后获取学生列表 */
        async scclaChange() {
            this.getStuUserList();
        },
        /** 获取学生列表  */
        async getStuUserList() {
            if(this.bindingDialog==false){
                return false
            }
          let searchForm = this.bindingForm;
          searchForm.pageindex=this.stuUserPage.pageIndex;
          searchForm.pagesize=this.stuUserPage.pagesize;
          this.tableLoad=true;
            let { data: res } = await $stuUserAllList(searchForm);
            if (res) {
                this.stuUserData = res.data;
                this.stuUserPage.total = res.allcount;
            }
            this.tableLoad=false;
        },
        /** 绑定 */
        async bindingStuUser() {
            if (this.bindingForm.sccla_id.length>0) {

              let stuser_idarr = [];
              if (this.bindingMultipleSelection.length > 0) {
                stuser_idarr = this.bindingMultipleSelection.map((item) => item.stuser_id);
              }
                let res = await $bindingStuUser({
                    sccla_id: this.bindingForm.sccla_id,
                    pli_id: this.bindingForm.pli_id,
                    stuser_idarr: stuser_idarr,
                });
                if (res.status==200) {
                    this.$message({
                      type: "success",
                      duration: 1500,
                      message: "绑定成功",
                    });
                    this.bindingDialog = false;
                    this.getData();
                }
            }else{
                this.$message({
                    message: "请选择学期和班级",
                    type: "warning",
                });
            }
        },
        /** 开启绑定记录弹框 */
        showBindingListDialog(pli_id) {
            this.bindingListDialog = true;
            this.getboardList(pli_id);
        },
        /** 关闭绑定记录弹框 */
        hidebindingListDialog() {
            this.bindingListDialog = false;
            this.bindingData = [];
        },
        /**  获取绑定记录 */
        async getboardList(pli_id) {
            this.bindingDataLoad = true;
            let { data: res } = await $bindingList({pli_id:pli_id});
            if (res) {
                this.bindingData = res;
            }
            this.bindingDataLoad = false;
        },
        async BindingExport(pls_id) {
            this.exportLoading = true;
            let searchForm = {
                pls_id: pls_id,
            };
            let { data: res } = await $exportPlaBoardSend(searchForm);
            this.exportLoading = false;
            console.log(res);
            if (res.status != true) {
                this.$message({
                    message: res.msg,
                    type: "error",
                });
                return;
            }
            let iframe = document.createElement("iframe");
            iframe.src = res.data.full_file_url;
            iframe.style = "display:none";
            document.body.appendChild(iframe);
            this.$message({
                message: res.msg,
                type: "success",
            });
        },
        /** 开启一键回收弹框 */
        showRecoveryDialog(pli_id) {
            this.recoveryDialog = true;
            this.getHistory(pli_id);
        },
        hideRecoveryDialog() {
            this.recoveryDialog = false;
            this.pls_id = "";
            this.historyData = [];
            this.recoveryData = [];
        },
        /* 获取绑定批次 */
        async getHistory(pli_id) {
            let { data: res } = await $bindingList({pli_id:pli_id});
            console.log(res);
            if (res) {
                this.historyData = res;
            }
        },
        /* 选择绑定批次后获取批次历史记录 */
        async historyChange(val) {
            this.historyDataLoad = true;
            let { data: res } = await $getHhistory({ pls_id: this.pls_id });
            console.log(res);
            if (res) {
                this.recoveryData = res;
            }
            this.historyDataLoad = false;
        },
        /* 一键回收 */
        async recoveryBoard() {
          let stuser_idarr = [];
          if (this.recoveryMultipleSelection.length > 0) {
            stuser_idarr = this.recoveryMultipleSelection.map((item) => item.stuser_id);
          }
            let { data: res } = await $recoveryBoard({ pls_id: this.pls_id,stuser_idarr:stuser_idarr });
            if (res) {
                this.$message({
                    type: "success",
                    duration: 1500,
                    message: "回收成功",
                });
                this.historyChange();
                this.getData();
            }
        },
    },
};
</script>
