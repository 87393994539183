<!----empty
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-02-28 21:27:27
 * @LastEditTime: 2023-03-13 09:48:10
 * @Descripttion: 试卷详情
-->
<style lang="scss" scoped>
.examination-detail {
    @include innerPage(38px 32px 48px 15px);
    @include pageTitle(0);

    .paper {
        &-detail {
            width: calc(100% + 32px);
            height: calc(100% - 86px);
            margin-top: 32px;
            box-sizing: border-box;
            overflow: hidden;
            overflow-y: auto;
            padding-right: 32px;
            padding-top: 12px;

            .paper-title {
                color: #484848;
                line-height: 24px;
                text-align: center;
                margin-bottom: 14px;
                position: relative;
                box-sizing: border-box;
                padding: 0 100px;
                margin-bottom: 20px;

                span.bold {
                    font-size: 24px;
                }

                .el-button {
                    position: absolute;
                    top: -9px;
                    right: 4px;
                }
            }

        }

        &-note {
            width: calc(100% - 12px);
            height: 90px;
            border-radius: 10px;
            background: rgba($color: #feaf2f, $alpha: 0.05);
            box-sizing: border-box;
            padding: 20px 30px;
            margin-left: 12px;

            &-inner {
                width: 100%;
                height: 100%;
                line-height: 24px;
                overflow: hidden;
                overflow-y: auto;

                span.score {
                    margin-left: 24px
                }

                .rule {
                    @include flexBox;
                    align-items: baseline;
                    box-sizing: border-box;
                    padding-right: 20px;

                    span {
                        flex-shrink: 0;
                    }

                    p {
                        text-align: justify;
                    }
                }
            }
        }

        &-questions {
            width: calc(100% - 12px);
            margin-left: 12px;
            margin-top: 30px;

            .questions-type {
                .title {
                    color: #4c4c4c;
                    line-height: 36px;
                    margin-bottom: 14px;
                }

                &:last-child {
                    .question-compose {
                        margin-bottom: 0;
                    }
                }
            }

            .question {
                &-list {
                    width: calc(100% - 84px);
                    box-sizing: border-box;
                    padding-left: 28px;
                }

                &-item {
                    width: 98%;
                    margin-bottom: 38px;
                }

                &-compose {
                    width: 98%;
                    min-height: 290px;
                    margin-bottom: 38px;
                    background: #eef0f8;
                    border-radius: 10px;
                    box-sizing: border-box;
                    padding: 30px 48px 40px;

                    &-wrapper {
                        width: 100%;

                        .compose-title {
                            @include flexBox;
                            align-items: baseline;

                            span {
                                line-height: 24px;

                                &.num {
                                    margin-right: 4px;
                                    font-size: 16px;
                                }
                            }

                            .imgs-group {
                                padding-left: 0;
                                margin-bottom: 0;
                                margin-top: 16px;
                            }
                        }

                        h5 {
                            line-height: 24px;
                        }

                        .subtopic {
                            margin-top: 20px;

                            &-item {
                                width: 100%;
                                box-sizing: border-box;
                                padding: 20px 30px 20px;
                                background: #e8eaf3;
                                border-radius: 10px;
                                color: #666;
                                margin-bottom: 24px;

                                .imgs-group {
                                    padding-left: 50px;
                                }

                                &:last-child {
                                    margin-bottom: 0;
                                }
                            }

                            &--title {
                                font-size: 16px;
                                line-height: 24px;
                                margin: 18px 0;
                            }

                            &--options {
                                box-sizing: border-box;
                                padding: 0 40px;
                                margin-top: 16px;

                                .option {
                                    line-height: 30px;

                                    p {
                                        display: inline-block;
                                        position: relative;
                                    }

                                    .imgs-group {
                                        padding-left: 18px;
                                    }

                                    &.is_answer p {
                                        &::after {
                                            content: '\e63a';
                                            font-family: 'iconfont';
                                            width: 20px;
                                            height: 20px;
                                            border-radius: 50%;
                                            background: #2ac293;
                                            text-align: center;
                                            line-height: 20px;
                                            color: #fff;
                                            position: absolute;
                                            right: -30px;
                                            top: 5px;
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                        }

                        .question-answer {
                            padding-left: 20px;
                        }
                    }
                }

                &-title {
                    font-size: 16px;
                    color: #404040;
                    line-height: 28px;
                    margin: 18px 0;
                    @include flexBox;
                    align-items: baseline;

                    .num,
                    .type {
                        flex-shrink: 0;
                        line-height: 24px;
                    }

                    .num {
                        color: #1f1f1f;
                        font-size: 16px;
                    }

                    .type {
                        min-width: 50px;
                        height: 24px;
                        background: #6340c8;
                        border-radius: 12px 0px 12px 12px;
                        text-align: center;
                        color: #fff;
                        margin: 0 14px 0 4px;
                        font-size: 14px;
                        box-sizing: border-box;
                        padding: 0 4px;
                    }
                }

                &-options,
                &-answer {
                    box-sizing: border-box;
                    padding-left: 100px;
                    padding-right: 32px;
                    line-height: 26px;
                    color: #666;

                    .option {
                        margin-top: 10px;

                        p {
                            display: inline-block;
                            position: relative;
                        }

                        &:first-child {
                            margin-top: 0;
                        }
                    }
                }

                &-answer {
                    display: inline-block;
                    position: relative;
                    text-align: justify;

                    span {
                        text-decoration: underline;
                        color: #2ac293;
                    }

                    &::after {
                        content: '\e63a';
                        font-family: 'iconfont';
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background: #2ac293;
                        text-align: center;
                        line-height: 20px;
                        color: #fff;
                        position: absolute;
                        right: 0;
                        top: 3px;
                        font-size: 12px;
                    }
                }

            }

            .imgs-group {
                margin-bottom: 24px;
                box-sizing: border-box;
                padding-left: 98px;
                @include flexBox;

                .el-image {
                    width: 130px;
                    height: 78px;
                    border-radius: 6px;
                    margin-right: 10px;
                }
            }

            .option {
                .imgs-group {
                    margin-bottom: 16px;
                    padding-left: 18px;
                    margin-top: 8px;
                }

                &.is_answer p {
                    &::after {
                        content: '\e63a';
                        font-family: 'iconfont';
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background: #2ac293;
                        text-align: center;
                        line-height: 20px;
                        color: #fff;
                        position: absolute;
                        right: -30px;
                        top: 3px;
                        font-size: 12px;
                    }
                }
            }
        }
    }
}

.audit {
    &-box {
        box-sizing: border-box;
        padding: 50px 0 0 0;
        margin-top: 50px;
        border-top: 1px solid #e1e2eb;

    }

    &-result {
        width: calc(100% - 20px);
        margin: 30px auto 0;

        &--inner {
            width: 100%;
            background: #f6f7fc;
            border-radius: 10px;
            box-sizing: border-box;
            padding: 20px 28px;

            p {
                width: 760px;
                min-height: 46px;
                box-sizing: border-box;
                padding: 11px 0;
                font-size: 16px;
                color: #484848;
                @include flexBox(flex-start, flex-start);

                span {
                    display: inline-block;
                    line-height: 24px;

                    &.label {
                        width: 84px;
                        padding-right: 20px;
                        text-align: right;
                        box-sizing: border-box;
                    }

                    &.failed {
                        color: #aeaeae;
                    }

                    &.primary {
                        color: #f8c436;
                    }

                    &.success {
                        color: #3bc8af;
                    }
                }
            }
        }

        .el-button {
            margin-top: 50px;
        }
    }

    &-form {
        width: 760px;
        box-sizing: border-box;
        padding-top: 30px;
        padding-left: 20px;

        .buttons {
            margin-top: 60px;
        }
    }
}

.office_form {
    margin-bottom: 50px;

    h3 {
        text-align: center;
        margin: 30px 0;
    }

    .office {
        margin: 0 auto;
        width: 800px;
    }
}
</style>

<template>
    <section class="examination-detail">
        <div class="page-title">
            <h3>试卷详情</h3>
            <el-button type="custom_primary" size="medium" @click="$router.go(-1)">
                返回
            </el-button>
        </div>
        <div class="paper-detail">
            <h5 class="paper-title">
                <span class="bold">{{ detailData[`${keyPrefix}pap_title`] }}</span>
                <el-button type="custom_primary" size="medium" v-if="paperType == 1"
                    @click="$router.push({ name: 'RESOURCES_EXAMINATION_PAPER_MODIFY', params: { id: paperId } })">
                    编辑试卷
                </el-button>
            </h5>
            <div class="paper-note">
                <div class="paper-note-inner">
                    <p>
                        <span>&gt;考试科目：{{ detailData.sysub_name }}</span>
                        <span class="score">总分：{{ detailData[`${keyPrefix}pap_sumscore`] }}分</span>
                    </p>
                    <div class="rule">
                        <span>&gt;考试规则：</span>
                        <p>{{ detailData[`${keyPrefix}pap_rule`] }}</p>
                    </div>
                </div>
            </div>
            <div class="office_form"
                v-if="(detailData.scpap_make_type == 20 || detailData.plpap_make_type == 20) && office_paper_url">
                <h3>试卷</h3>
                <!-- pdf展示数据--试卷 -->
                <div class="office">
                    <vueOffice v-model="office_paper_url" type="pdf"></vueOffice>
                </div>
            </div>
            <div class="office_form"
                v-if="(detailData.scpap_make_type == 20 || detailData.plpap_make_type == 20) && scpap_att_analysis_url">
                <h3>解析</h3>
                <!-- pdf展示数据--解析 -->
                <div class="office">
                    <vueOffice v-model="scpap_att_analysis_url" type="pdf"></vueOffice>
                </div>
            </div>
            <ul class="paper-questions" v-if="detailData.scpap_make_type == 10||detailData.plpap_make_type == 10">
                <li class="questions-type" v-for="(qg, qg_index) in detailData.paper_question" :key="qg_index">
                    <h5 class="bold title">
                        {{ qg_index + 1 | changeChinesNum }}、{{ qg[`${keyPrefix}pap_top_title`] }}（共{{
                qg[`${keyPrefix}pap_top_sumscore`] }}分）
                    </h5>
                    <!-- 组合题 -->
                    <template v-if="qg.syque_typ_id == 14">
                        <div class="question-compose" v-for="(que, que_index) in qg.question_info" :key="que_index">
                            <div class="question-compose-wrapper">
                                <div class="compose-title">
                                    <span class="num pf_bold" style="flex: none;">
                                        {{ que_index + 1 > 9 && que_index + 1 || `0${que_index + 1}` }}.
                                    </span>
                                    <h5>
                                        <span class="bold">
                                            <span class="richblock" v-html="que[`${keyPrefix}que_title`] + '(' + que[`${keyPrefix}que_score`] + '分)'"></span>
                                        </span>
                                        <div class="imgs-group" v-if="que[`${keyPrefix}que_title_image`]">
                                            <el-image :src="formatfile(que[`${keyPrefix}que_title_image`])"
                                                :preview-src-list="[formatfile(que[`${keyPrefix}que_title_image`])]" />
                                        </div>
                                    </h5>
                                </div>
                                <ol class="subtopic">
                                    <li class="subtopic-item" v-for="(sque, sque_index) in que.question_arr"
                                        :key="sque_index">
                                        <p class="subtopic--title">
                                            （{{ sque_index + 1 }}）【{{ sque.syque_typ_name }}】
                                            <span class="richinline" v-html="sque[`${keyPrefix}que_title`]"></span>（ {{ sque[`${keyPrefix}que_score`] }}分 ）
                                        </p>
                                        <div class="imgs-group" v-if="sque[`${keyPrefix}que_title_image`]">
                                            <el-image :src="formatfile(sque[`${keyPrefix}que_title_image`])"
                                                :preview-src-list="[formatfile(sque[`${keyPrefix}que_title_image`])]" />
                                        </div>
                                        <dl class="subtopic--options" v-if="isChoice(sque.syque_typ_id)">
                                            <dt class="option"
                                                :class="{ is_answer: opt[`${keyPrefix}que_ite_isanswer`] == 10 }"
                                                v-for="(opt, opt_index) in sque.question_item" :key="opt_index">
                                                <p>{{ opt[`${keyPrefix}que_ite_code`] }}. 
                                                    <span class="richinline" v-html="opt[`${keyPrefix}que_ite_title`]"></span>
                                                </p>
                                                <div class="imgs-group" v-if="opt[`${keyPrefix}que_ite_image`]">
                                                    <el-image :src="formatfile(opt[`${keyPrefix}que_ite_image`])"
                                                        :preview-src-list="[formatfile(opt[`${keyPrefix}que_ite_image`])]" />
                                                </div>
                                            </dt>
                                        </dl>
                                        <p class="question-answer" v-if="!isChoice(sque.syque_typ_id) && !$isEmpty(sque.sure_answer)">
                                            <span class="richinline" v-html="sque.sure_answer.join(',')"></span>
                                        </p>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </template>
                    <ol class="question-list" v-if="qg.syque_typ_id != 14">
                        <li class="question-item" v-for="(que, que_index) in qg.question_info" :key="que_index">
                            <p class="question-title">
                                <span class="num pf_bold">
                                    {{ que_index + 1 > 9 && que_index + 1 || `0${que_index + 1}` }}.
                                </span>
                                <span class="type">{{ que.syque_typ_name }}</span>

                                <span class="richflex"
                                    v-html="que[`${keyPrefix}que_title`] + '(' + que[`${keyPrefix}que_score`] + '分)'"></span>
                                <!-- <span>{{ que[`${keyPrefix}que_title`] }}（ {{ que[`${keyPrefix}que_score`] }}分 ）</span> -->

                            </p>
                            <!-- 题干图片 -->
                            <div class="imgs-group" v-if="que[`${keyPrefix}que_title_image`]">
                                <img class="selectimg" v-if="formatfile(que[`${keyPrefix}que_title_image`])" :src="formatfile(que[`${keyPrefix}que_title_image`])" alt="">
                            </div>
                            <!-- 选择题 -->
                            <dl class="question-options" v-if="isChoice(que.syque_typ_id)">
                                <dt class="option" :class="{ is_answer: opt[`${keyPrefix}que_ite_isanswer`] == 10 }"
                                    v-for="(opt, opt_index) in que.question_item" :key="opt_index">
                                    <p>
                                        {{ opt[`${keyPrefix}que_ite_code`] }}. 
                                        <span class="richinline" v-html="opt[`${keyPrefix}que_ite_title`]"></span>
                                    </p>
                                    <!-- 选项图片 -->
                                    <div class="imgs-group" v-if="opt[`${keyPrefix}que_ite_image`]">
                                        <img class="selectimg" v-if="formatfile(opt[`${keyPrefix}que_ite_image`])" :src="formatfile(opt[`${keyPrefix}que_ite_image`])" alt="">
                                    </div>
                                </dt>
                            </dl>
                            <!-- 非选择题 -->
                            <p class="question-answer" v-if="!isChoice(que.syque_typ_id) && !$isEmpty(que.sure_answer)">
                                <span class="richinline" v-html="que.sure_answer.join(',')"></span>
                            </p>
                        </li>
                    </ol>
                </li>
            </ul>
            <div class="audit-box"
                v-if="(paperType == 1 && detailData[`${keyPrefix}pap_isshare`] > 10) || $route.params.audit == 2">
                <div class="page-title">
                    <h3>{{ $route.params.audit == 2 && '平台审核信息' || '审核信息' }}</h3>
                </div>
                <div class="audit-result">
                    <div class="audit-result--inner">
                        <p>
                            <span class="label">审核</span>
                            <span class="value"
                                :class="{ failed: detailData[`${keyPrefix}pap_isshare`] == 30, success: detailData[`${keyPrefix}pap_isshare`] == 20, primary: detailData[`${keyPrefix}pap_isshare`] == 15 }">
                                {{ detailData[`${keyPrefix}pap_isshare`] == 30 && '驳回' ||
                detailData[`${keyPrefix}pap_isshare`] == 20 && '已通过' || '待审核' }}
                            </span>
                        </p>
                        <template v-if="detailData[`${keyPrefix}pap_isshare`] > 15">
                            <p>
                                <span class="label">审核意见</span>
                                <span class="value">{{ detailData[`${keyPrefix}pap_reason`] || '-' }}</span>
                            </p>
                            <p>
                                <span class="label">审核人</span>
                                <span class="value">{{ detailData.examine_name || '-' }}</span>
                            </p>
                            <p>
                                <span class="label">审核时间</span>
                                <span class="value">{{ detailData.examine_time | formatTime('YYYY/MM/DD HH:mm')
                                    }}</span>
                            </p>
                        </template>
                    </div>
                    <el-button type="custom_primary" size="small" v-if="detailData[`${keyPrefix}pap_isshare`] == 30"
                        @click="sharePaper">
                        重新共享
                    </el-button>
                </div>
            </div>
            <!-- 审核 -->
            <div class="audit-box" v-if="$route.params.audit == 1">
                <div class="page-title">
                    <h3>审核信息</h3>
                </div>
                <div class="audit-result" v-if="detailData[`${keyPrefix}pap_examine`] > 10">
                    <div class="audit-result--inner">
                        <p>
                            <span class="label">审核</span>
                            <span class="value"
                                :class="{ failed: detailData[`${keyPrefix}pap_examine`] == 30, success: detailData[`${keyPrefix}pap_examine`] == 20, primary: detailData[`${keyPrefix}pap_examine`] == 10 }">
                                {{ detailData[`${keyPrefix}pap_examine`] == 30 && '驳回' ||
                detailData[`${keyPrefix}pap_examine`] == 20 && '已通过' || '待审核' }}
                            </span>
                        </p>
                        <p v-if="detailData[`${keyPrefix}pap_examine`] > 10">
                            <span class="label">审核意见</span>
                            <span class="value">{{ detailData[`${keyPrefix}pap_reason`] || '-' }}</span>
                        </p>
                        <p>
                            <span class="label">审核人</span>
                            <span class="value">{{ detailData.examine_name || '-' }}</span>
                        </p>
                        <p>
                            <span class="label">审核时间</span>
                            <span class="value">{{ detailData.examine_time | formatTime('YYYY/MM/DD HH:mm') }}</span>
                        </p>
                    </div>
                </div>
                <el-form ref="auditForm" :model="auditForm" label-width="84px" class="audit-form" :rules="auditRules"
                    v-if="detailData[`${keyPrefix}pap_examine`] == 10">
                    <el-form-item label="审核：">
                        <el-radio-group v-model="auditForm.scpap_examine">
                            <el-radio :label="20">通过</el-radio>
                            <el-radio :label="30">驳回</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="审核意见：" prop="sccou_reason">
                        <el-input v-model.trim="auditForm.scpap_reason" type="textarea" maxlength="200"
                            placeholder="请输入审核意见" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="custom_info" size="small" @click="$router.go(-1)">
                            取 消
                        </el-button>
                        <el-button type="custom_primary" size="small" :disabled="submitLoad" @click="submitAduitResult"
                            v-loading="submitLoad">
                            确 定
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </section>
</template>

<script>
import { $exampaperDetail, $shareExampaper } from "@api/resources"
import { $paperDetail, $auditPaper } from "@api/audit"
import { formatFile } from "@utils"
import vueOffice from "@comp/vueOffice"
export default {
    name: 'examinationDetail',
    components: {
        vueOffice
    },
    computed: {
        formatfile() {
            return function (url) {
                return formatFile(url)
            }
        },
        /** 判断是否是选择题 */
        isChoice() {
            const choiceTypeIds = [1, 2, 5];
            return function (val) {
                return choiceTypeIds.indexOf(val) != -1;
            };
        },
        auditClass() {
            let status = this.detailData[`${this.keyPrefix}pap_isshare`];
            let className = '';
            switch (status) {
                case 15:
                    className = "primary"
                    break;
                case 20:
                    className = "success"
                    break;
                case 30:
                    className = "failed"
                    break;
                default:
                    break;
            }
            return className;
        },
        auditText() {
            let status = this.detailData[`${this.keyPrefix}pap_isshare`];
            let text = '';
            switch (status) {
                case 15:
                    text = "待审核"
                    break;
                case 20:
                    text = "已通过"
                    break;
                case 30:
                    text = "已驳回"
                    break;
                default:
                    break;
            }
            return text;
        }
    },
    data() {
        let validateReason = (rule, value, callback) => {
            let { scpap_examine, scpap_reason } = this.auditForm
            if (scpap_examine == 30 && !scpap_reason) {
                callback(new Error("请填写审核意见"));
            } else {
                callback();
            }
        };
        return {
            paperId: null,
            paperType: 1,
            detailData: {},
            keyPrefix: 'sc',
            auditForm: { scpap_examine: 20 },
            auditRules: {
                sccou_reason: [
                    { required: true, validator: validateReason, trigger: "blur" },
                ],
            },
            submitLoad: false,
            office_paper_url: "",
            scpap_att_analysis_url: "",
        }
    },
    created() {
        let { id, type, audit } = this.$route.params
        this.paperId = id;
        this.paperType = type;
        this.keyPrefix = type == 2 && 'pl' || 'sc'

        if (audit) this.getAuditDetail();
        else this.getDetail();
    },
    methods: {
        /** 获取试卷详情 */
        async getDetail() {
            let { data } = await $exampaperDetail(this.paperType, this.paperId);
            console.log(data)
            this.detailData = { ...data };
            if (data.plpap_make_type == 20) {
                this.office_paper_url = data.plpap_att_paper_url
                this.scpap_att_analysis_url = data.plpap_att_analysis_url
            }
            if (data.scpap_make_type == 20) {
                this.office_paper_url = data.scpap_att_paper_url
                this.scpap_att_analysis_url = data.scpap_att_analysis_url
            }
            this.$forceUpdate();
        },
        /** 获取审核试卷资源详情 */
        async getAuditDetail() {
            let { data } = await $paperDetail(this.paperId);
            this.detailData = { ...data }
            if (data.plpap_make_type == 20) {
                this.office_paper_url = data.plpap_att_paper_url
                this.scpap_att_analysis_url = data.plpap_att_analysis_url
            }
            if (data.scpap_make_type == 20) {
                this.office_paper_url = data.scpap_att_paper_url
                this.scpap_att_analysis_url = data.scpap_att_analysis_url
            }
            this.$forceUpdate();
        },
        /** 提交审核结果 */
        submitAduitResult() {
            this.$refs.auditForm.validate(async (valid) => {
                if (valid) {
                    this.submitLoad = true;
                    let params = {
                        ...this.auditForm,
                        scpap_id: this.paperId
                    }
                    let res = await $auditPaper(params);
                    this.submitLoad = false;
                    if (res) {
                        this.$message({
                            type: "success",
                            duration: 1500,
                            message: "审核结果提交成功",
                        });
                        this.$refs.auditForm.resetFields();
                        this.auditForm = { scpap_examine: 20 };
                        this.getAuditDetail();
                    }
                }
            });
        },
        /** 共享试卷 */
        sharePaper() {
            this.$msgbox({
                title: "提示",
                message: "确认将选中试卷共享至平台？",
                type: "info",
                showClose: false,
                showCancelButton: true,
                cancelButtonClass: "el-button--custom_info",
                confirmButtonClass: "el-button--custom_primary",
                confirmButtonText: "确定",
                cancelButtonText: "取消",
            }).then(async () => {
                let res = await $shareExampaper(this.paperId);
                if (res) {
                    this.$message.success('试卷共享申请已提交，等待平台审核');
                    if (this.$route.params.audit) this.getAuditDetail();
                    else this.getDetail();
                }
            }).catch(() => {
                this.$message({
                    type: "info",
                    duration: 1500,
                    message: "操作已取消",
                });
            });
        },
    }
}
</script>